<template>
  <section>
    <TitleBar tabsParent="recolte">
      Campagne {{ $route.params.codeRecolte }}
      <template #toolbar-content>
        <b-button-group v-if="presaisieAutorisee">
          <b-button
            pill
            variant="primary"
            @click="dupliquerEnlevement('PRESAISIE')"
          >
            <b-icon-check-all></b-icon-check-all>
            Tout pr&eacute;remplir
          </b-button>
        </b-button-group>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
        &nbsp;
        <ExportButtonGroup
          @imprimer="() => {}"
          @exportExcel="() => {}"
          @exportCsv="() => {}"
        ></ExportButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
        @keydown.native.esc="load"
        @keydown.native.enter="onEnter"
      >
        <template #mobile-card="{ item }">
          <EnlevementCard
            :item="item"
            @view="openEnlevementCultureEditor('VISUALISATION', item)"
            @viewEmb="
              (emb) =>
                openEnlevementEmblavementEditor('VISUALISATION', item, emb)
            "
            @edit="openEnlevementCultureEditor('EDITION', item)"
            @editEmb="
              (emb) => openEnlevementEmblavementEditor('EDITION', item, emb)
            "
            @duplicate="(enl) => dupliquerEnlevement('DUPLICATION', enl)"
            @delete="deleteEnlevementCulture(item)"
            @deleteEmb="deleteEnlevementEmblavement"
          ></EnlevementCard>
        </template>
        <template #cell(expand)="row">
          <ToggleRowComponent :row="row" @toggle="toggleCulture(row)">
            Afficher les parcelles
          </ToggleRowComponent>
        </template>
        <template #row-details="{ item }">
          <EnlevementDetailsComponent
            :culture="item"
            @cancel="load"
            @validate="enlevementChanged"
            @comment="openCommentaireEmblavementEditor"
            @view="
              (emb) =>
                openEnlevementEmblavementEditor('VISUALISATION', item, emb)
            "
            @edit="
              (emb) => openEnlevementEmblavementEditor('EDITION', item, emb)
            "
            @duplicate="
              (emb) => dupliquerEnlevement('DUPLICATION', emb.enlevement)
            "
            @delete="deleteEnlevementEmblavement"
          ></EnlevementDetailsComponent>
        </template>
        <template #cell(libelleProduit)="{ item }">
          {{ item.libelleProduit }}
          <MelangeIcon v-if="item.isMelangeEspeces">
            M&eacute;lange d'esp&egrave;ces
          </MelangeIcon>
        </template>
        <template #cell(libelleVariete)="{ item }">
          {{ item.libelleVariete }}
          <MelangeIcon v-if="item.isMelangeVarietes">
            M&eacute;lange de vari&eacute;t&eacute;s
          </MelangeIcon>
        </template>
        <template #cell(quantiteACollecter)="{ item }">
          <AnomalieIcon
            :anomaliesAutoconso="['QUANTITE_INVALIDE']"
            :forceDisplay="item.quantiteACollecter < 0"
          ></AnomalieIcon>
          <span :id="`quantite-culture-${item.id}`">
            <MontantComponent
              :value="item.quantiteACollecter"
            ></MontantComponent>
          </span>
          <QuantiteCollecteTooltip
            :target="`quantite-culture-${item.id}`"
            :quantitePrevisionnelle="item.quantitePrevisionnelle"
            :quantiteMoissonnee="item.quantiteMoissonnee"
            :quantiteAutoconsommee="item.quantiteAutoconsommee"
          ></QuantiteCollecteTooltip>
        </template>
        <template #cell(modeEnlevement)="{ item }">
          <section v-if="item.enlevement">
            <SelectModeComponent
              v-if="editing === item.id"
              :ref="`selectMode_${item.id}`"
              label=""
              :societe="exploitationCourante.codeSociete"
              rules="required"
              v-model="item.enlevement.codeMode"
            ></SelectModeComponent>
            <template v-else>
              <AnomalieIcon
                v-if="item.enlevement.anomalies.includes('MODE_INVALIDE')"
                :anomaliesEnlevement="['MODE_INVALIDE']"
              ></AnomalieIcon>
              <BadgeComponent
                :text="item.enlevement.mode.libelle"
                :variant="`list${item.enlevement.mode.ordre}`"
                @click="quickEdit(item.id)"
              ></BadgeComponent>
            </template>
          </section>
          <b-link
            v-else
            href="#"
            @click="openEnlevementCultureEditor('EDITION', item)"
          >
            <AnomalieIcon :anomaliesCulture="item.anomalies"></AnomalieIcon>
            <em v-if="item.saisieEmblavements">
              Enl&egrave;vement &agrave; la parcelle
            </em>
            <template v-else>Saisir un enl&egrave;vement</template>
          </b-link>
        </template>
        <template #cell(dateEnlevement)="{ item }">
          <template
            v-if="item.enlevement && item.enlevement.codeMode !== 'NONE'"
          >
            <InputDateEnlevementComponent
              v-if="editing === item.id"
              :ref="`inputDate_${item.id}`"
              label=""
              :readOnly="item.multiplication"
              :enMoisson="item.enlevement.enMoisson"
              @enMoissonChanged="
                (checked) => (item.enlevement.enMoisson = checked)
              "
              :dateEnlevement="item.enlevement.dateLimite"
              @dateChanged="
                (value) => {
                  // FIXME Rustine pour gérer la date souhaitée
                  item.enlevement.dateSouhaitee = value;
                  item.enlevement.dateLimite = value;
                }
              "
            ></InputDateEnlevementComponent>
            <b-link
              v-else-if="item.enlevement.enMoisson"
              class="quick-edit-link"
              href="#"
              @click="quickEdit(item.id)"
            >
              En moisson
            </b-link>
            <template v-else>
              <AnomalieIcon
                v-if="item.enlevement.anomalies.includes('DATE_INVALIDE')"
                classes="mr-3"
                :anomaliesEnlevement="['DATE_INVALIDE']"
              ></AnomalieIcon>
              <b-link
                class="quick-edit-link"
                href="#"
                @click="quickEdit(item.id)"
              >
                {{ isoSqlDateToFrenchDate(item.enlevement.dateLimite) }}
              </b-link>
            </template>
          </template>
        </template>
        <template #cell(actions)="{ item }">
          <EnlevementActionsComponent
            :item="item"
            :editing="editing === item.id"
            @cancel="load"
            @validate="validate(item)"
            @comment="openCommentaireCultureEditor(item)"
            @view="openEnlevementCultureEditor('VISUALISATION', item)"
            @edit="openEnlevementCultureEditor('EDITION', item)"
            @duplicate="dupliquerEnlevement('DUPLICATION', item.enlevement)"
            @delete="deleteEnlevementCulture(item)"
          ></EnlevementActionsComponent>
        </template>
        <template #pagination>
          A collecter :
          <strong>
            <MontantComponent :value="quantiteACollecter"></MontantComponent>
            t
          </strong>
          <span v-if="periodeEnlevement">
            | Du <strong>{{ periodeEnlevement[0] }}</strong> au
            <strong>{{ periodeEnlevement[1] }}</strong>
          </span>
        </template>
      </PaginatedTable>
      <EnlevementEditionDialog ref="enlevementEditionDialog">
      </EnlevementEditionDialog>
      <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
      <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ExportButtonGroup from "../../components/controls/ExportButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import EnlevementCard from "../../components/cards/EnlevementCard.vue";
import ToggleRowComponent from "../../components/controls/ToggleRowComponent.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import QuantiteCollecteTooltip from "../../components/controls/QuantiteCollecteTooltip.vue";
import EnlevementDetailsComponent from "../../components/EnlevementDetailsComponent.vue";
import BadgeComponent from "../../components/BadgeComponent.vue";
import AnomalieIcon from "../../components/controls/AnomalieIcon.vue";
import SelectModeComponent from "../../components/inputs/SelectModeComponent.vue";
import InputDateEnlevementComponent from "../../components/inputs/InputDateEnlevementComponent.vue";
import EnlevementActionsComponent from "../../components/controls/EnlevementActionsComponent.vue";
import EnlevementEditionDialog from "../../components/dialogs/EnlevementEditionDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "EnlevementsView",
  components: {
    TitleBar,
    TableButtonGroup,
    ExportButtonGroup,
    ErrorComponent,
    PaginatedTable,
    EnlevementCard,
    ToggleRowComponent,
    MontantComponent,
    QuantiteCollecteTooltip,
    EnlevementDetailsComponent,
    BadgeComponent,
    AnomalieIcon,
    SelectModeComponent,
    InputDateEnlevementComponent,
    EnlevementActionsComponent,
    EnlevementEditionDialog,
    CommentaireDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.ENLEVEMENTS),
      error: null,
      page: null,
      culturesOuvertes: [],
      editing: null,
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    presaisieAutorisee() {
      return (
        // FIXME this.$store.state.decl.enlevements.length === 0 &&
        this.$store.state.decl.emblavements.length > 0
      );
    },
    quantiteACollecter() {
      return this.$store.getters["decl/quantiteACollecter"];
    },
    periodeEnlevement() {
      return this.$store.getters["decl/periodeEnlevement"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    load() {
      try {
        this.busy = true;
        this.editing = null;

        // Récupérer les données dans le store
        let cultures = UtilsService.deepCopy(this.$store.state.decl.cultures)
          .map((c) => {
            // Récupérer les emblavements
            c.emblavements = UtilsService.deepCopy(
              this.$store.state.decl.parcelles
                .filter((p) => p.idCulture === c.id)
                .flatMap((p) =>
                  this.$store.getters["decl/emblavementsFromParcelle"](p.id)
                )
            )
              .map((e) => {
                // Récupérer les infos ilot/parcelle
                e.parcelle = this.$store.getters["decl/parcelle"](e.idParcelle);
                e.parcelle.ilot = this.$store.getters["decl/ilot"](
                  e.parcelle.idIlot
                );
                e.parcelle.libelleParcelle = `${e.parcelle.ilot.libelle} - ${e.parcelle.libelle}`;

                // Récupérer les infos produit/variété
                e.produit = this.$store.getters["ref/produitCereale"](
                  e.codeProduit
                );
                e.variete = this.$store.getters["ref/variete"](e.codeVariete);
                e.espece = this.$store.getters["ref/espece"](
                  e.produit?.codeEspece
                );

                // Récupérer les infos de débouché
                if (e.produit) {
                  e.produit.debouche = this.$store.getters["ref/debouche"](e.produit.codeDebouche);
                }

                // Récupérer l'autoconso et calculer les quantités
                e.autoconso = this.$store.getters[
                  "decl/autoconsoFromEmblavement"
                ](e.id);
                // FIXME Faire mieux pour ventiler l'autoconso à la culture...
                e.quantiteAutoconsommee =
                  e.autoconso?.quantiteAutoconsommee ??
                  this.$store.getters["decl/quantiteAutoconsoEmblavement"](
                    e.id
                  );

                // Quantité prévisionnelle XOR moissonnée (pas les deux)
                if (e.quantiteMoissonnee != null) {
                  e.quantitePrevisionnelle = null;
                }
                e.quantiteACollecter =
                  (e.quantitePrevisionnelle ?? 0.0) +
                  (e.quantiteMoissonnee ?? 0.0) -
                  e.quantiteAutoconsommee;

                // Récupérer l'enlèvement
                e.enlevement = UtilsService.deepCopy(
                  this.$store.getters["decl/enlevementFromEmblavement"](e.id)
                );
                if (e.enlevement) {
                  e.enlevement.mode = this.$store.getters["ref/modeEnlevement"](
                    e.enlevement.codeMode
                  );
                }
                return e;
              })
              .sort(
                UtilsService.sortBy(
                  UtilsService.sortByStringProperty("parcelle.libelleParcelle"),
                  UtilsService.sortByStringProperty("espece.libelle")
                )
              );

            // Récupérer l'autoconso et calculer les quantités
            c.autoconso = this.$store.getters["decl/autoconsoFromCulture"](
              c.id
            );
            c.quantiteAutoconsommee =
              c.autoconso?.quantiteAutoconsommee ??
              c.emblavements.reduce(
                (acc, next) => acc + (next.quantiteAutoconsommee ?? 0.0),
                0.0
              );
            c.quantitePrevisionnelle = c.emblavements.reduce(
              (acc, next) => acc + (next.quantitePrevisionnelle ?? 0.0),
              0.0
            );
            c.quantiteMoissonnee = c.emblavements.reduce(
              (acc, next) => acc + (next.quantiteMoissonnee ?? 0.0),
              0.0
            );
            c.quantiteACollecter =
              c.quantitePrevisionnelle +
              c.quantiteMoissonnee -
              c.quantiteAutoconsommee;

            // Récupérer l'enlèvement
            c.enlevement = UtilsService.deepCopy(
              this.$store.getters["decl/enlevementFromCulture"](c.id)
            );
            if (c.enlevement) {
              c.enlevement.mode = this.$store.getters["ref/modeEnlevement"](
                c.enlevement.codeMode
              );
            }

            // Calculer les statuts à l'avance
            c.multiplication = !!c.emblavements.find((e) => e.produit?.debouche?.multiplication);
            c.saisieEmblavements = !!c.emblavements.find((e) => !!e.enlevement);

            // Ouvrir les enlèvements à la parcelle
            c._showDetails = this.culturesOuvertes.includes(c.id);
            return c;
          })
          .filter((c) => c.emblavements.length > 0);

        // Filtrer la liste
        cultures = cultures.filter(this.getFilterFunction());

        // Trier et paginer le résultat
        this.page = UtilsService.readOnlyPage(
          cultures,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          this.getSortFunction()
        );
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async quickEdit(idCulture) {
      this.editing = idCulture;
      if (!idCulture) {
        return;
      }
      await this.$nextTick();
      this.$refs[`inputDate_${idCulture}`]?.focus();
      this.$refs[`selectMode_${idCulture}`]?.focus();
    },
    async onEnter() {
      let target = this.page.content.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    async dupliquerEnlevement(mode, enlevement) {
      try {
        let enlevements = await this.$refs.enlevementEditionDialog.show({
          mode,
          enlevement,
        });
        if (!enlevements) {
          return;
        }
        for (const e of enlevements) {
          await this.$store.dispatch("decl/createUpdateEnlevement", e);
        }
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
    toggleCulture(row) {
      if (row.item._showDetails) {
        this.culturesOuvertes = this.culturesOuvertes.filter(
          (id) => id !== row.item.id
        );
      } else {
        this.culturesOuvertes.push(row.item.id);
      }
      row.toggleDetails();
    },
    async openCommentaireCultureEditor(culture) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Culture",
        items: [
          culture.isMelangeEspeces
            ? culture.libelleProduit
            : `${culture.libelleProduit} - ${culture.libelleVariete}`,
        ],
        labelCommentaire: "Commentaire enlèvement",
        description:
          "Utilisez cet espace pour fournir des précisions à votre coopérative sur l'enlèvement.",
        commentaire: culture.enlevement.commentaire,
      });
      if (resultat != null) {
        culture.enlevement.commentaire = resultat;
        this.enlevementChanged(culture.enlevement);
      }
    },
    async openCommentaireEmblavementEditor(emblavement) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Emblavement",
        items: [
          `Parcelle : ${emblavement.parcelle.libelleParcelle}`,
          `Culture : ${
            emblavement.semeEnMelangeEspeces
              ? emblavement.produit.libelle
              : `${emblavement.produit.libelle} - ${emblavement.variete.libelle}`
          }`,
        ],
        labelCommentaire: "Commentaire enlèvement",
        description:
          "Utilisez cet espace pour fournir des précisions à votre coopérative sur l'enlèvement.",
        commentaire: emblavement.enlevement.commentaire,
      });
      if (resultat != null) {
        emblavement.enlevement.commentaire = resultat;
        this.enlevementChanged(emblavement.enlevement);
      }
    },
    async confirmSaisieCulture(culture) {
      // Si on ne trouve aucun enlèvement sur les emblavements
      if (!culture.emblavements.find((e) => !!e.enlevement)) {
        return true;
      }
      // Sinon on demande confirmation avant d'écraser
      return await this.$refs.confirmationDialog.show({
        title: "Définir un enlèvement à la culture ?",
        messages: [
          `Vous avez défini un enlèvement à la parcelle, êtes-vous sûr.e de vouloir définir un enlèvement à la culture ?`,
          `L'enlèvement à la culture remplacera les enlèvements à la parcelle, qui seront supprimés.`,
        ],
      });
    },
    async openEnlevementCultureEditor(mode, culture) {
      let parent = this;
      this.enlevementChanged(
        await this.$refs.enlevementEditionDialog.show({
          mode,
          culture,
          confirmationProvider: async function () {
            return await parent.confirmSaisieCulture(culture);
          },
        })
      );
    },
    async confirmSaisieEmblavement(culture) {
      // Si on ne trouve aucune autoconso sur la culture
      if (!culture.enlevement) {
        return true;
      }
      // Sinon on demande confirmation avant d'écraser
      return await this.$refs.confirmationDialog.show({
        title: "Définir un enlèvement à la parcelle ?",
        messages: [
          `Vous avez défini un enlèvement à la culture, êtes-vous sûr.e de vouloir définir un enlèvement à la parcelle ?`,
          `L'enlèvement actuel à la culture sera utilisé pour préremplir les enlèvements à la parcelle.`,
        ],
      });
    },
    async openEnlevementEmblavementEditor(mode, culture, emblavement) {
      let parent = this;
      this.enlevementChanged(
        await this.$refs.enlevementEditionDialog.show({
          mode,
          culture,
          emblavement,
          confirmationProvider: async function () {
            return await parent.confirmSaisieEmblavement(culture);
          },
        })
      );
    },
    enlevementChanged(enlevement) {
      if (enlevement) {
        this.$store
          .dispatch("decl/createUpdateEnlevement", enlevement)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async validate(culture) {
      await this.$refs[`selectMode_${culture.id}`].validate();
      await this.$refs[`inputDate_${culture.id}`]?.validate();
      this.enlevementChanged(culture.enlevement);
    },
    async deleteEnlevementCulture(culture) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Supprimer l'enlèvement ?",
        messages: [
          `Etes-vous sûr de vouloir supprimer l'enlèvement de la culture ${culture.libelleProduit} ? Cette action sera irréversible.`,
        ],
      });
      if (confirmation) {
        this.$store
          .dispatch("decl/deleteEnlevement", culture.enlevement.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async deleteEnlevementEmblavement(emblavement) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Supprimer l'enlèvement ?",
        messages: [
          `Etes-vous sûr de vouloir supprimer l'enlèvement de la parcelle ${emblavement.parcelle.libelleParcelle} ? Cette action sera irréversible.`,
        ],
      });
      if (confirmation) {
        this.$store
          .dispatch("decl/deleteEnlevement", emblavement.enlevement.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
  },
};
</script>
