<template>
  <b-modal
    ref="modal"
    scrollable
    @shown="$refs.selectMode.focus()"
    @close="cancel"
  >
    <template #modal-title>{{ title }}</template>
    <template v-if="enlevement">
      <SelectCulturesComponent
        ref="selectCultures"
        v-if="mode === 'DUPLICATION'"
        description="Seules les cultures où l'enlèvement est à saisir sont sélectionnables."
        :anomalie="anomaliesCulture.ENLEVEMENT_NON_SAISI"
        v-model="culturesDestination"
      ></SelectCulturesComponent>
      <p v-else-if="mode === 'PRESAISIE'">
        <em>
          Cette saisie s'appliquera &agrave; toutes les cultures sans
          enl&egrave;vement et pour lesquelles la saisie d'un enl&egrave;vement
          est demand&eacute;e.
        </em>
      </p>
      <b-container v-else class="context-container">
        <GenericReadonlyComponent
          v-if="emblavement"
          label="Parcelle : "
          labelCols="auto"
          :value="emblavement.parcelle.libelleParcelle"
        ></GenericReadonlyComponent>
        <GenericReadonlyComponent
          v-if="culture"
          label="Culture : "
          labelCols="auto"
          :value="culture.libelleProduit"
        ></GenericReadonlyComponent>
        <GenericReadonlyComponent
          v-if="culture"
          label="Variété : "
          labelCols="auto"
          :value="culture.libelleVariete"
        ></GenericReadonlyComponent>
      </b-container>
      <SelectModeComponent
        ref="selectMode"
        label="Mode d'enlèvement :"
        :labelCols="{ md: 5 }"
        :readOnly="readOnly"
        :societe="exploitationCourante.codeSociete"
        rules="required"
        v-model="enlevement.codeMode"
      ></SelectModeComponent>
      <template v-if="enlevement.codeMode !== 'NONE'">
        <InputDateEnlevementComponent
          ref="inputDate"
          label="Date limite d'enlèvement :"
          :labelCols="{ md: 5 }"
          :readOnly="readOnly || multiplication"
          :enMoisson="enlevement.enMoisson"
          @enMoissonChanged="(checked) => (enlevement.enMoisson = checked)"
          :dateEnlevement="enlevement.dateLimite"
          @dateChanged="
            (value) => {
              // FIXME Rustine pour gérer la date souhaitée
              enlevement.dateSouhaitee = value;
              enlevement.dateLimite = value;
            }
          "
        ></InputDateEnlevementComponent>
        <!-- Transformations -->
        <template v-if="mode !== 'DUPLICATION' && mode !== 'PRESAISIE'">
          <b-form-group
            v-if="culture.isMelangeEspeces"
            label="Mélange séparé à la ferme :"
            label-cols="auto"
            label-cols-md="5"
          >
            <!-- FIXME Attention booléen inversé -->
            <b-form-checkbox
              switch
              :disabled="readOnly"
              :value="false"
              :unchecked-value="true"
              v-model="enlevement.livreEnMelangeEspeces"
            >
              {{ enlevement.livreEnMelangeEspeces ? "non" : "oui" }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Séché à la ferme :"
            label-cols="auto"
            label-cols-md="5"
          >
            <b-form-checkbox
              switch
              :disabled="readOnly"
              v-model="enlevement.livreSeche"
            >
              {{ enlevement.livreSeche ? "oui" : "non" }}
            </b-form-checkbox>
          </b-form-group>
        </template>
      </template>
      <!-- Commentaire enlèvement -->
      <InputCommentaireComponent
        label="Commentaire enlèvement :"
        :readOnly="readOnly"
        v-model="enlevement.commentaire"
      ></InputCommentaireComponent>
      <p v-if="!readOnly">
        <em>
          Vous pouvez maintenant modifier vos enl&egrave;vements &agrave; tout
          moment de l'ann&eacute;e !
        </em>
      </p>
    </template>
    <template #modal-footer>
      <b-button v-if="readOnly" block pill variant="primary" @click="edit">
        Modifier
      </b-button>
      <b-container v-else fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import SelectCulturesComponent from "../inputs/SelectCulturesComponent.vue";
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
import SelectModeComponent from "../inputs/SelectModeComponent.vue";
import InputDateEnlevementComponent from "../inputs/InputDateEnlevementComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "EnlevementEditionDialog",
  components: {
    SelectCulturesComponent,
    GenericReadonlyComponent,
    SelectModeComponent,
    InputDateEnlevementComponent,
    InputCommentaireComponent,
  },
  data: () => ({
    id: `enlevement-${uuidv4()}`,
    mode: null,
    culturesDestination: [],
    culture: null,
    emblavement: null,
    enlevement: null,
    confirmationProvider: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    codeRecolte() {
      return this.$route.params.codeRecolte;
    },
    anomaliesCulture() {
      return this.$store.state.decl.anomaliesCulture;
    },
    title() {
      if (this.mode === "CREATION") {
        return "Saisir mon souhait d'enlèvement";
      }
      if (this.mode === "EDITION") {
        return "Modifier mon souhait d'enlèvement";
      }
      if (this.mode === "DUPLICATION") {
        return "Dupliquer mon souhait d'enlèvement";
      }
      return "Mon souhait d'enlèvement";
    },
    listeEmblavements() {
      if (this.emblavement) {
        return [this.emblavement];
      }
      return this.culture?.emblavements ?? [];
    },
    multiplication() {
      // On est en multi si au moins 1 produit a ce débouché
      return !!this.listeEmblavements
        .find((e) => e.produit?.debouche?.multiplication);
    },
    periodeMoisson() {
      // On est en moisson tardive si au moins 1 produit l'est
      let moissonTardive = this.listeEmblavements
        .map((e) => this.$store.getters["ref/espece"](e.produit?.codeEspece))
        .map((e) => e?.periodeMoisson === "AUTOMNE")
        .reduce((acc, next) => acc || next, false);
      return moissonTardive ? "AUTOMNE" : "ETE";
    },
    readOnly() {
      return this.mode === "VISUALISATION";
    },
  },
  methods: {
    getNouvelEnlevement() {
      return {
        codeMode: null,
        enMoisson: true,
        dateSouhaitee: null,
        dateLimite: null,
        livreEnMelangeEspeces: this.culture?.isMelangeEspeces === true,
        livreEnMelangeVarietes:
          this.culture?.isMelangeVarietes === true ||
          this.emblavement?.semeEnMelangeVarietes === true,
        livreSeche: false,
        // Soit emblavement soit culture, mais jamais les deux
        idCulture: this.emblavement?.id ? null : this.culture?.id,
        idEmblavement: this.emblavement?.id,
      };
    },
    /**
     * Options disponibles :
     * - mode = VISUALISATION, EDITION, PRESAISIE ou DUPLICATION (VISUALISATION par défaut)
     * - culture = requis uniquement en EDITION d'un nouvel enlèvement
     * - emblavement = requis uniquement en EDITION d'un nouvel enlèvement
     * - enlevement = objet de travail
     *
     * En mode PRESAISIE ou DUPLICATION, un enlèvement peut être fourni pour présaisie ou non
     * Si PRESAISIE, toutes les cultures à saisir sont automatiquement sélectionnées
     * Si DUPLICATION, l'utilisateur sélectionne les cultures auxquelles appliquer l'enlèvement
     * Culture et emblavement ne sont pas utilisés dans ces deux modes
     */
    async show(options) {
      this.mode = options?.mode ?? "VISUALISATION";
      this.confirmationProvider = options?.confirmationProvider ?? (() => true);
      // Si on ouvre en modification, appeler le confirmationProvider
      // Si la réponse est non, ne pas ouvrir la popup
      if (this.mode === "EDITION" && !(await this.confirmationProvider())) {
        return;
      }

      this.culturesDestination = [];
      this.culture = options?.culture;
      this.emblavement = options?.emblavement;

      // Initialisation de l'objet de travail selon options
      if (this.mode === "PRESAISIE" || this.mode === "DUPLICATION") {
        this.enlevement = UtilsService.deepCopy(options?.enlevement);
      } else if (this.emblavement) {
        this.enlevement = UtilsService.deepCopy(this.emblavement?.enlevement);
      } else if (this.culture) {
        this.enlevement = UtilsService.deepCopy(this.culture?.enlevement);
      }
      // Fallback si enlèvement null (création)
      if (!this.enlevement) {
        this.enlevement = this.getNouvelEnlevement();
      }

      // Si multiplication, récupérer la date d'enlèvement imposée correspondante
      await this.$nextTick();
      if (this.multiplication) {
        let dateEnlevement = this.$store.getters["ref/dateEnlevementMulti"](
          this.codeRecolte,
          this.periodeMoisson
        );
        this.enlevement.enMoisson = false;
        this.enlevement.dateSouhaitee = dateEnlevement;
        this.enlevement.dateLimite = dateEnlevement;
      }

      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async edit() {
      // Si on passe en modification, appeler le confirmationProvider
      // Si la réponse est non, rester en visualisation
      if (await this.confirmationProvider()) {
        this.mode = "EDITION";
      }
    },
    async validate() {
      await this.$refs.selectMode.validate();
      await this.$refs.inputDate?.validate();
      this.$refs["modal"].hide();

      // Si présaisie, on sélectionne automatiquement toutes les cultures à saisir
      if (this.mode === "PRESAISIE") {
        this.culturesDestination = this.$store.state.decl.cultures.filter((c) =>
          c.anomalies.includes(this.anomaliesCulture.ENLEVEMENT_NON_SAISI)
        );
      }

      // Si duplication ou présaisie, on génère les enlèvements à partir des cultures sélectionnées
      if (this.mode === "DUPLICATION" || this.mode === "PRESAISIE") {
        this.resolvePromise(
          this.culturesDestination.map((c) => {
            let enlevement = UtilsService.deepCopy(this.enlevement);
            enlevement.id = null;
            enlevement.idCulture = c.id;
            enlevement.livreEnMelangeEspeces = c.isMelangeEspeces;
            enlevement.livreEnMelangeVarietes = c.isMelangeVarietes;
            enlevement.livreSeche = false;
            return enlevement;
          })
        );
      } else {
        this.resolvePromise(this.enlevement);
      }
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
